/*
    Structure
*/
main {
    @extend .wrapper;
    padding-bottom: 1rem;
}

img {
    width: auto;
    max-width: 100%;
    height: auto;
}

.wrapper {
    max-width: $lrg;
    width: 95%;
    margin: 0 auto;
}

[class*='__container'], [class*='__wrapper'] {
    @extend .wrapper;
}

[class*='__flex-container'] {
    @extend .wrapper;
    display: flex;
}



// Grid wrapper using flex + BEM
.grid {

    // Define the flex container
    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item-4-col {
        width: 23.5%;
        margin-right: 2%;
        margin-bottom: 1.5em;

        @media (min-width: $med) {
            &:nth-of-type(4n) {
                margin-right: 0;
            }
        }

        @media (max-width: $med) {
            width: 49%;

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        @media (max-width: $mob) {
            width: 100%;
            margin-right: 0;
        }
    }

    &__item-3-col {
        width: 32%;
        margin-right: 2%;
        margin-bottom: 1.5em;

        @media (min-width: $med) {
            &:nth-of-type(3n) {
                margin-right: 0;
            }
        }

        @media (max-width: $med) {
            width: 49%;

            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        @media (max-width: $mob) {
            width: 100%;
            margin-right: 0;
        }
    }
    
    &__item-2-col {
        width: 49%;
        margin-right: 2%;
        margin-bottom: 1.5em;

        @media (min-width: $mob) {
            &:nth-of-type(2n) {
                margin-right: 0;
            }
        }

        @media (max-width: $mob) {
            width: 100%;
            margin-right: 0;
        }

    }
}


// CSS Grid
$gridcolumns: 12;
$class: grid;

// loops through 100 times
@for $i from 1 through $gridcolumns {
    .#{$class}--#{$i} {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat($i, 1fr);
        width: 100%;

        @media (max-width: 1200px) {
            grid-template-columns: 50% 25% 25% repeat(3, 1fr);
        }

        @media (max-width: $mob) {
            grid-template-columns: 1fr;
        }
    }

    [data-grid-columns="#{$i}"] {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat($i, 1fr);
        width: 100%;

        @media (max-width: $tablet) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width: $mob) {
            grid-template-columns: 1fr;
        }
    }
}