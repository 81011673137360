/* Tablet */

@media only screen and (max-width: $med) {

    

    .icons-section {
        padding:3rem 0;
        .wrapper {
            h2 {
                font-family: "Poppins",sans-serif;
                font-weight: 300;
                font-size: 3.5rem;
                letter-spacing: -0.03em;
                line-height: 4.5rem;
                text-align: center;
                color: #000;
                margin-bottom:10px;
                strong {
                    font-weight: 500;
                }
            }
            p {
                text-align: center;
                font-size:1.6rem;
            }
            .icons-list {
                display:flex;
                flex-wrap:wrap;
                .icon-item {
                    flex: 0 1 50%;
                    .circle {
                        background: #005855;
                        border-radius: 50%;
                        height: 150px;
                        width: 150px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin:0 auto;
                        img {
                            width: 70px;
                        }
                    }
                    h4 {
                        margin-top:10px;
                        margin-bottom:25px;
                        text-align: center;
                        font-size:1.6rem;
                        color:#000000;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .menu {
        display:none;
    }
    .logo {
        text-align: center;
    }
    .journeyform {
        background-color:#005855;
        padding:2rem 3rem;
        .wrapper {
            display:flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            div {
                flex: 0 1 45%;
                h3 {
                    font-family: Poppins;
                    font-weight: normal;
                    font-size: 24px;
                    text-align: left;
                    color: #fff;
                    margin-bottom:5px;
                }
                label {
                    font-family: Poppins;
                    font-weight: normal;
                    font-size: 18px;
                    text-align: left;
                    color: #fff;
                    margin-bottom: 10px;
                }
                select, input {
                    margin-top:5px;
                }
                select {
                    background-color:transparent;
                    border:none;
                    border: 0.5px solid #fff;
                    color:#ffffff;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #fff;
                    //padding-left:0;
                    margin-bottom:2rem;
                    background: url('assets/img/down-arrow.png') no-repeat 98% 50%;
                    background-size:8%;
                    -moz-appearance: none; 
                    -webkit-appearance: none; 
                    appearance: none;
                    border-radius: 0;
                }
                input {
                    background-color:transparent;
                    border:none;
                    border: 1px solid #fff !important;
                    color:#ffffff;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #fff;
                    padding-left:1rem !important;
                    margin-bottom:2rem;
                    max-width:100%;
                    margin-top:0;
                    &::placeholder {
                        color:#ffffff;
                    }
                }
                .button {
                    margin-top:10px;
                    border:none;
                    border-radius: 19px;
                    background: #00ff78;
                    font-family: $site-font;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: center;
                    color: #000;
                    margin-bottom:3rem;
                    &:hover {
                        background-position: 100% 0;
                        moz-transition: all .4s ease-in-out;
                        -o-transition: all .4s ease-in-out;
                        -webkit-transition: all .4s ease-in-out;
                        transition: all .4s ease-in-out;
                        //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                        box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                    }
                }
                #postcode-error, #phone1-error, #email-error, #firstname-error, #lastname-error {
                    margin-bottom:0 !important;
                    padding:8px;
                    background-color:#ffffff;
                    color:#005855;
                }
            }
        }
    }
    #a7 {
        .left {
            width:100%;
        }
    }
    .thank-you {

        background-color:#005855;
        .wrapper {

            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding:0 2rem;
            h1 {
                font-family: $site-font;
                font-weight: 500;
                font-size: 2rem;
                letter-spacing: -0.03em;
                line-height: 3rem;
                text-align: center;
                color: #fff;
            }
            img {
                max-width:350px !important;
            }
            p {
                font-family: $site-font;
                font-weight: normal;
                font-size: 2rem;
                line-height: 2.6rem;
                text-align: center;
                color: #fff;
                max-width:95%;
            }
        }
    }
.hero-with-image {
    background-color:#005855;
    .wrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        h1 {
            font-family: $heading-font;
            font-weight: 300;
            font-size: 4rem;
            letter-spacing: -0.03em;
            line-height: 4.4rem;
            text-align: center;
            color: #fff;
            strong {
                font-weight: 500;
            }
        }
        p {
            max-width:575px;
            text-align: center;
            font-family: $site-font;
            font-weight: normal;
            font-size: 2rem;
            line-height: 2.6rem;
            text-align: center;
            color: #fff;
            margin-bottom:3rem;
        }
        img {
            margin-bottom:1rem;
            max-width:50%;
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
        .oversized-image {
            margin-bottom:-120px;
            img {
                margin-bottom: 0;
                max-width:100%;
            };
        }
    }
}

.text_image_alt {
    padding-top:8rem;
    background-color:#F2F2F2;
    .wrapper {
        padding-top:9rem;
        padding-bottom:10rem;
        padding-left:2rem;
        padding-right:2rem;
        display:flex;
        flex-direction: column;
        div {
            flex: 0 1 50%;
            &:nth-child(1) {
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                padding-right:3rem;
            }
            &:nth-child(2) {
                display:flex;
                justify-content: center;

                align-items: center;
            }
            h4 {
                margin:0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.4rem;
                letter-spacing: 0.1em;
                line-height: 2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 3.6rem;
                letter-spacing: -0.03em;
                line-height: 4.3rem;
                text-align: left;
                color: #000;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
            }
            .button {
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
        }
    }
}

.image_text {
    background-color:#FFFFFF;
    .wrapper {
        padding-top:4rem;
        padding-bottom:5rem;
        display:flex;
        flex-direction: column;
        padding-left:2rem;
        padding-right:2rem;
        div {
            flex: 0 1 50%; 
            h4 {
                margin:0;
                margin:2rem 0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                letter-spacing: 0.1em;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 3.6rem;
                letter-spacing: -0.03em;
                line-height: 4.3rem;
                text-align: left;
                color: #000;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
            }
            .button {
                margin-left:0;
                margin-top:2%;
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .bullets {
                .bullet {
                    display:flex;
                    div {
                        &:nth-child(1) {
                            flex:0 1 10%;
                        }
                        flex: 0 1 100%;
                        h5 {
                            font-family: $site-font;
                            font-weight: 600;
                            font-size: 2rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #005855;
                            margin-bottom:0;
                            margin-top:4px;
                            margin-left:10px;
                        }
                        p {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #000;
                            margin-left:10px;
                        }
                    }
                }
            }
        }
    }
}

.text_image_alt_bullets {
    padding-top:120px;
    background-color:#F2F2F2;
    .wrapper {
        padding-top:5rem;
        padding-bottom:6rem;
        padding-left:2rem;
        padding-right:2rem;
        display:flex;
        flex-direction: column;
        div {
            flex: 0 1 50%; 
            &:nth-child(2) {
                display: flex;
                justify-content: flex-end;
            }
            h4 {
                margin:0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.4rem;
                letter-spacing: 0.1em;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 3.6rem;
                letter-spacing: -0.03em;
                line-height: 4.3rem;
                text-align: left;
                color: #000;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
            }
            .button {
                margin-top:2%;
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .bullets {
                display:flex;
                .bullet {
                    display:flex;
                    margin-bottom:2rem;
                    justify-content: flex-start;
                    div {
                        flex:0 1 100%;
                        width:100%;
                        justify-content: flex-start !important;
                        &:nth-child(1) {
                            flex:0 1 15%;
                        }
                        &:nth-child(2) {
                            display:flex;
                            justify-content: flex-start !important;
                        }
                        h5 {
                            font-family: $site-font;
                            font-weight: 600;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #005855;
                            margin-left:10px;
                            margin-top:5px;
                        }
                        p {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.calculator {
    background-color:#005855;
    padding-top:5rem;
    .wrapper {
        display:flex;
        flex-direction: column;
        h3 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 3.7rem;
            letter-spacing: -0.03em;
            line-height: 4.5rem;
            text-align: center;
            color: #fff;
        }
        p {
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
            color: #fff;
            max-width:90%;
            margin:0 auto;
            margin-bottom:5rem;
        }

        .calculator-section {
            max-width:95%;
            padding:3rem;
            width:100%;
            margin:0 auto;
            margin-bottom:-120px;
            border-radius: 20px;
            background: #fff;
            box-shadow: 6px 7px 16px rgba(0, 0, 0, 0.16);
            .question {
                display:flex;
                flex-wrap: wrap;
                margin-bottom:2rem;
                select {
                    margin-top:1rem;
                    border-radius: 4px;
                    background: #f4f4f4;
                    font-family:Arial, Helvetica, sans-serif;
                    height:47px;
                    font-weight:400;
                    border-radius: 23.5px;
                    background: #fff;
                    border: 2px solid #235755;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
                    -moz-appearance: none; 
                    -webkit-appearance: none; 
                    appearance: none;
                    padding-left:2rem;
                }
                input {
                    padding-left:2rem;
                    margin-top:1rem;
                    border-radius: 4px;
                    background: #f4f4f4;
                    font-family:Arial, Helvetica, sans-serif;
                    height:47px;
                    font-weight:400;
                    border-radius: 23.5px;
                    background: #fff;
                    border: 2px solid #235755;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    //background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
                    -moz-appearance: none; 
                    -webkit-appearance: none; 
                    appearance: none;
                }
                &:nth-child(3) {
                    div {
                        width:100%;
                        flex:0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                &:nth-child(4) {
                    div {
                        width:100%;
                        flex:0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                h5 {
                    width:100%;
                    font-family: $site-font;
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 2.2rem;
                    text-align: left;
                    color: #005855;
                }
                div {
                    flex: 0 1 100%;
                    font-family: $site-font;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .checkboxes {
                    display:flex;
                    flex-wrap:wrap;
                    flex-direction: row;
                    justify-content: flex-start;
                    .checkbox {
                        flex: 0 1 100%;
                        width:100 !important;
                        display:flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        padding:1rem;
                        input {
                            flex: 0 1 10%;
                            align-items: center;
                        }
                        label {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.4rem;
                            line-height: 2rem;
                            text-align: left;
                            color: #000;
                        }
                    }
                }
            }
            .sep {
                display:none;
                max-width:100%;
                margin:0 auto;
                background: transparent;
                border: 1px solid #707070;
                margin-top:2rem;
                margin-bottom: 2rem;
            }
            .button {
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .results-total {    
                height: 47px;
                border-radius: 23.5px;
                background: #fff;
                border: 2px solid #235755;
                padding:1.5rem;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.3rem;
                line-height: 2rem;
                text-align: left;
                color: #000;
            }
        }
    }
}

input[type="checkbox"] {
  display:none;
  opacity: 0;
  z-index: -1;
}

input[type="checkbox"]+span:before {
    content: '';
  border: 1px solid grey;
  border-radius: 10px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  margin-top: 0.5em;
  vertical-align: -2px;
}

input[type="checkbox"]:checked+span:before {
    /* NOTE: Replace the url with a path to an SVG of a checkmark to get a checkmark icon */
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/collection/build/ionicons/svg/ios-checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    /* The size of the checkmark icon, you may/may not need this */
    background-size: 25px;
    border-radius: 10px;
    //background-color: #e7ffba;
    color: white;
  }

  input[type="checkbox"]:focus+span:before,
input[type="checkbox"]:not(:disabled)+span:hover:before {
  /* Visible in the full-color space */
  box-shadow: 0px 0px 0px 2px #005855;

  /* Visible in Windows high-contrast themes
     box-shadow will be hidden in these modes and
     transparency will not be hidden in high-contrast
     thus box-shadow will not show but the outline will
     providing accessibility */
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
  }


/* Disabled checkbox styles */
input[type="checkbox"]:disabled+span {
  cursor: default;
  color: black;
  opacity: 0.5;
}

input {
    height:52px;
}

.journey-question {
    height: 100vh;
    background-color:#005855;
    overflow-y: hidden;
    .wrapper {
        .left {
            max-width:100%;
        }
        .right {
            max-width:100%;
            img {
                max-width:100%;
            }
        }
        padding:0 2rem;
        height:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            letter-spacing: 0.1em;
            line-height: 2.2rem;
            text-align: left;
            color: #fff;
        }
        h2 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 2.5rem;
            letter-spacing: -0.03em;
            line-height: 3.3rem;
            text-align: left;
            color: #fff;
        }
        h4 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 2rem;
            letter-spacing: -0.03em;
            line-height: 2.2rem;
            text-align: left;
            color: #fff;
        }
        select {
            background-color:transparent;
            border:none;
            border-bottom: 0.5px solid #fff;
            color:#ffffff;
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: left;
            color: #fff;
            padding-left:0;
            margin-bottom:4rem;
        }
        input {
            background-color:transparent;
            border:none;
            border: 1px solid #ffffff !important;
            color:#ffffff;
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: left;
            color: #fff;
            padding-left:0;
            margin-bottom:4rem;
            &::placeholder {
                color:#ffffff;
            }
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
    }
}

#postcode-error {
    color:#ffffff;
    margin:0 !important;
    margin-bottom:1.5rem !important;
}

.hero-with-journey {
    background-color:#ffffff;
    padding-top: 3rem;
    padding-bottom:3rem;
    .wrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .journey-questions {
            width:100%;
        }
        h1 {
            font-family: $heading-font;
            font-weight: 300;
            font-size: 2rem;
            letter-spacing: -0.03em;
            line-height: 3.3rem;
            text-align: center;
            color: #000000;
            max-width:80%;
            strong {
                font-weight: 500;
            }
        }
        p {
            max-width:575px;
            text-align: center;
            font-family: $site-font;
            font-weight: normal;
            font-size: 2rem;
            line-height: 2.6rem;
            text-align: center;
            color: #000000;
            margin-bottom:3rem;
        }
        img {
            margin-bottom:3rem;
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
        .progress-bar {
            height:10px;
            border:0.5px solid #000000;
            border-radius: 5px;
            position: relative;
            margin-top:2rem;
            .progress {
                position: absolute;
                border-radius: 5px;
                top:1px;
                bottom:1px;
                left:1px;
                height:6px;
                background-color:#00ff78;
                width:20%;
            }
            .progress-numbers {
                position:absolute;
                right:5px;
                bottom:-25px;
                color:#000000;
                font-size: 12px;
            }
        }
        #question1, #question2, #question3, #question4 {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem;
            position: relative;
            h4 {
                color:#000000;
                margin-top:2rem;
                margin-bottom:2rem;
                font-size:2rem;
                text-align: center;
            }
            .back2,.back3,.back4 {
                position: absolute;
                top:2%;
                left:0;
                color:#000000;
                &:hover {
                    cursor: pointer;
                }
            }
            .answers {
                display:flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                .ans1, .ans2, .ans3, .ans4 {
                    background-color: transparent;
                    flex:0 1 100%;
                    margin:1rem;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height:70px;
                    border-radius: 5px;
                    border:1px solid #000000;
                    color:#000000;
                    padding:2rem;
                    &:hover {
                        cursor: pointer;
                        border:2px solid #00ff78;
                    }
                }
            }
        }
        #question5 {
            padding:2rem;
            p {
                font-size:1.6rem !important;
                font-weight: normal;
                max-width:80%;
             }
            h1 {
                margin:0;
                max-width:100%;
                font-size:2.8rem;
                margin-bottom:2rem;
            }
            form {
                input {
                    margin-bottom:2rem !important;
                    border-radius: 5px;
                    color:#000000;
                }
                button {
                    float:right;
                }
            }
            #result-debt {
                margin-top:1rem;
                line-height: 2.2rem;
                font-size:2rem;
            }
        }
    }

}

}

/* Mobile */

@media only screen and (max-width: $mob) {
    .menu {
        display:none;
    }
    .journeyform {
        background-color:#005855;
        padding:2rem 3rem;
        .wrapper {
            display:flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            div {
                flex: 0 1 45%;
                h3 {
                    font-family: Poppins;
                    font-weight: normal;
                    font-size: 24px;
                    text-align: left;
                    color: #fff;
                    margin-bottom:5px;
                }
                label {
                    font-family: Poppins;
                    font-weight: normal;
                    font-size: 18px;
                    text-align: left;
                    color: #fff;
                    margin-bottom: 10px;
                }
                select, input {
                    margin-top:5px;
                }
                select {
                    background-color:transparent;
                    border:none;
                    border: 0.5px solid #fff;
                    color:#ffffff;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #fff;
                    //padding-left:0;
                    margin-bottom:2rem;
                    background: url('assets/img/down-arrow.png') no-repeat 98% 50%;
                    background-size:8%;
                    -moz-appearance: none; 
                    -webkit-appearance: none; 
                    appearance: none;
                    border-radius: 0;
                }
                input {
                    background-color:transparent;
                    border:none;
                    border: 1px solid #fff !important;
                    color:#ffffff;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #fff;
                    padding-left:1rem !important;
                    margin-bottom:2rem;
                    max-width:100%;
                    margin-top:0;
                    &::placeholder {
                        color:#ffffff;
                    }
                }
                .button {
                    margin-top:10px;
                    border:none;
                    border-radius: 19px;
                    background: #00ff78;
                    font-family: $site-font;
                    font-weight: 500;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: center;
                    color: #000;
                    margin-bottom:3rem;
                    &:hover {
                        background-position: 100% 0;
                        moz-transition: all .4s ease-in-out;
                        -o-transition: all .4s ease-in-out;
                        -webkit-transition: all .4s ease-in-out;
                        transition: all .4s ease-in-out;
                        //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                        box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                    }
                }
                #postcode-error, #phone1-error, #email-error, #firstname-error, #lastname-error {
                    margin-bottom:0 !important;
                    padding:8px;
                    background-color:#ffffff;
                    color:#005855;
                }
            }
        }
    }
    #a7 {
        .left {
            width:100%;
        }
    }
    .thank-you {

        background-color:#005855;
        .wrapper {
     
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding:0 2rem;
            h1 {
                font-family: $site-font;
                font-weight: 500;
                font-size: 2rem;
                letter-spacing: -0.03em;
                line-height: 3rem;
                text-align: center;
                color: #fff;
            }
            img {
                max-width:250px !important;
            }
            p {
                font-family: $site-font;
                font-weight: normal;
                font-size: 2rem;
                line-height: 2.6rem;
                text-align: center;
                color: #fff;
                max-width:95%;
            }
        }
    }
.hero-with-image {
    background-color:#005855;
    .wrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 2rem;
        h1 {
            font-family: $heading-font;
            font-weight: 300;
            font-size: 4rem;
            letter-spacing: -0.03em;
            line-height: 4.4rem;
            text-align: center;
            color: #fff;
            strong {
                font-weight: 500;
            }
        }
        p {
            max-width:575px;
            text-align: center;
            font-family: $site-font;
            font-weight: normal;
            font-size: 2rem;
            line-height: 2.6rem;
            text-align: center;
            color: #fff;
            margin-bottom:3rem;
        }
        img {
            margin-bottom:1rem;
            max-width:50%;
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
        .oversized-image {
            margin-bottom:-120px;
            img {
                margin-bottom: 0;
                max-width:100%;
            };
        }
    }
}

.text_image_alt {
    padding-top:8rem;
    background-color:#F2F2F2;
    .wrapper {
        padding-top:9rem;
        padding-bottom:10rem;
        padding-left:2rem;
        padding-right:2rem;
        display:flex;
        flex-direction: column;
        div {
            flex: 0 1 50%;
            &:nth-child(1) {
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                padding-right:3rem;
            }
            &:nth-child(2) {
                display:flex;
                justify-content: flex-end;
            }
            h4 {
                margin:0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.4rem;
                letter-spacing: 0.1em;
                line-height: 2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 3.6rem;
                letter-spacing: -0.03em;
                line-height: 4.3rem;
                text-align: left;
                color: #000;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
            }
            .button {
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
        }
    }
}

.image_text {
    background-color:#FFFFFF;
    .wrapper {
        padding-top:4rem;
        padding-bottom:5rem;
        display:flex;
        flex-direction: column;
        padding-left:2rem;
        padding-right:2rem;
        div {
            flex: 0 1 50%; 
            h4 {
                margin:0;
                margin:2rem 0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                letter-spacing: 0.1em;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 3.6rem;
                letter-spacing: -0.03em;
                line-height: 4.3rem;
                text-align: left;
                color: #000;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
            }
            .button {
                margin-left:0;
                margin-top:2%;
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .bullets {
                .bullet {
                    display:flex;
                    div {
                        &:nth-child(1) {
                            flex:0 1 10%;
                        }
                        flex: 0 1 100%;
                        h5 {
                            font-family: $site-font;
                            font-weight: 600;
                            font-size: 2rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #005855;
                            margin-bottom:0;
                            margin-top:4px;
                            margin-left:10px;
                        }
                        p {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #000;
                            margin-left:10px;
                        }
                    }
                }
            }
        }
    }
}

.text_image_alt_bullets {
    padding-top:120px;
    background-color:#F2F2F2;
    .wrapper {
        padding-top:5rem;
        padding-bottom:6rem;
        padding-left:2rem;
        padding-right:2rem;
        display:flex;
        flex-direction: column;
        div {
            flex: 0 1 50%; 
            &:nth-child(2) {
                display: flex;
                justify-content: flex-end;
            }
            h4 {
                margin:0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.4rem;
                letter-spacing: 0.1em;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 3.6rem;
                letter-spacing: -0.03em;
                line-height: 4.3rem;
                text-align: left;
                color: #000;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
            }
            .button {
                margin-top:2%;
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .bullets {
                display:flex;
                .bullet {
                    display:flex;
                    margin-bottom:2rem;
                    justify-content: flex-start;
                    div {
                        flex:0 1 100%;
                        width:100%;
                        justify-content: flex-start !important;
                        &:nth-child(1) {
                            flex:0 1 15%;
                        }
                        &:nth-child(2) {
                            display:flex;
                            justify-content: flex-start !important;
                        }
                        h5 {
                            font-family: $site-font;
                            font-weight: 600;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #005855;
                            margin-left:10px;
                            margin-top:5px;
                        }
                        p {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.calculator {
    background-color:#005855;
    padding-top:5rem;
    .wrapper {
        display:flex;
        flex-direction: column;
        h3 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 3.7rem;
            letter-spacing: -0.03em;
            line-height: 4.5rem;
            text-align: center;
            color: #fff;
        }
        p {
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
            color: #fff;
            max-width:90%;
            margin:0 auto;
            margin-bottom:5rem;
        }

        .calculator-section {
            max-width:95%;
            padding:3rem;
            width:100%;
            margin:0 auto;
            margin-bottom:-120px;
            border-radius: 20px;
            background: #fff;
            box-shadow: 6px 7px 16px rgba(0, 0, 0, 0.16);
            .question {
                display:flex;
                flex-wrap: wrap;
                margin-bottom:2rem;
                select {
                    margin-top:1rem;
                    border-radius: 4px;
                    background: #f4f4f4;
                    font-family:Arial, Helvetica, sans-serif;
                    height:47px;
                    font-weight:400;
                    border-radius: 23.5px;
                    background: #fff;
                    border: 2px solid #235755;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
                    -moz-appearance: none; 
                    -webkit-appearance: none; 
                    appearance: none;
                    padding-left:2rem;
                }
                input {
                    padding-left:2rem;
                    margin-top:1rem;
                    border-radius: 4px;
                    background: #f4f4f4;
                    font-family:Arial, Helvetica, sans-serif;
                    height:47px;
                    font-weight:400;
                    border-radius: 23.5px;
                    background: #fff;
                    border: 2px solid #235755;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    //background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
                    -moz-appearance: none; 
                    -webkit-appearance: none; 
                    appearance: none;
                }
                &:nth-child(3) {
                    div {
                        width:100%;
                        flex:0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                &:nth-child(4) {
                    div {
                        width:100%;
                        flex:0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                h5 {
                    width:100%;
                    font-family: $site-font;
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 2.2rem;
                    text-align: left;
                    color: #005855;
                }
                div {
                    flex: 0 1 100%;
                    font-family: $site-font;
                    font-weight: normal;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .checkboxes {
                    display:flex;
                    flex-wrap:wrap;
                    flex-direction: row;
                    justify-content: flex-start;
                    .checkbox {
                        flex: 0 1 100%;
                        width:100 !important;
                        display:flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        padding:1rem;
                        input {
                            flex: 0 1 10%;
                            align-items: center;
                        }
                        label {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.4rem;
                            line-height: 2rem;
                            text-align: left;
                            color: #000;
                        }
                    }
                }
            }
            .sep {
                display:none;
                max-width:100%;
                margin:0 auto;
                background: transparent;
                border: 1px solid #707070;
                margin-top:2rem;
                margin-bottom: 2rem;
            }
            .button {
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .results-total {    
                height: 47px;
                border-radius: 23.5px;
                background: #fff;
                border: 2px solid #235755;
                padding:1.5rem;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.3rem;
                line-height: 2rem;
                text-align: left;
                color: #000;
            }
        }
    }
}

input[type="checkbox"] {
  display:none;
  opacity: 0;
  z-index: -1;
}

input[type="checkbox"]+span:before {
    content: '';
  border: 1px solid grey;
  border-radius: 10px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  margin-top: 0.5em;
  vertical-align: -2px;
}

input[type="checkbox"]:checked+span:before {
    /* NOTE: Replace the url with a path to an SVG of a checkmark to get a checkmark icon */
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/collection/build/ionicons/svg/ios-checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    /* The size of the checkmark icon, you may/may not need this */
    background-size: 25px;
    border-radius: 10px;
    //background-color: #e7ffba;
    color: white;
  }

  input[type="checkbox"]:focus+span:before,
input[type="checkbox"]:not(:disabled)+span:hover:before {
  /* Visible in the full-color space */
  box-shadow: 0px 0px 0px 2px #005855;

  /* Visible in Windows high-contrast themes
     box-shadow will be hidden in these modes and
     transparency will not be hidden in high-contrast
     thus box-shadow will not show but the outline will
     providing accessibility */
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
  }


/* Disabled checkbox styles */
input[type="checkbox"]:disabled+span {
  cursor: default;
  color: black;
  opacity: 0.5;
}

input {
    height:52px;
}

.journey-question {
    height: 100vh;
    background-color:#005855;
    overflow-y: hidden;
    .wrapper {
        .left {
            max-width:100%;
        }
        .right {
            max-width:100%;
            img {
                max-width:100%;
            }
        }
        padding:0 2rem;
        height:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            letter-spacing: 0.1em;
            line-height: 2.2rem;
            text-align: left;
            color: #fff;
        }
        h2 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 2.5rem;
            letter-spacing: -0.03em;
            line-height: 3.3rem;
            text-align: left;
            color: #fff;
        }
        h4 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 2rem;
            letter-spacing: -0.03em;
            line-height: 2.2rem;
            text-align: left;
            color: #fff;
        }
        select {
            background-color:transparent;
            border:none;
            border-bottom: 0.5px solid #fff;
            color:#ffffff;
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: left;
            color: #fff;
            padding-left:0;
            margin-bottom:4rem;
        }
        input {
            background-color:transparent;
            border:none;
            border: 1px solid #ffffff !important;
            color:#ffffff;
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: left;
            color: #fff;
            padding-left:0;
            margin-bottom:4rem;
            &::placeholder {
                color:#ffffff;
            }
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
    }
}

#postcode-error {
    color:#ffffff;
    margin:0 !important;
    margin-bottom:1.5rem !important;
}

.hero-with-journey {
    background-color:#ffffff;
    padding-top: 3rem;
    padding-bottom:3rem;
    .wrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .journey-questions {
            width:100%;
        }
        h1 {
            font-family: $heading-font;
            font-weight: 300;
            font-size: 2.3rem;
            letter-spacing: -0.03em;
            line-height: 3.3rem;
            text-align: center;
            color: #000000;
            max-width:95%;
            margin-bottom:2rem;
            strong {
                font-weight: 500;
            }
        }
        p {
            max-width:575px;
            text-align: center;
            font-family: $site-font;
            font-weight: normal;
            font-size: 2rem;
            line-height: 2.6rem;
            text-align: center;
            color: #000000;
            margin-bottom:3rem;
        }
        img {
            margin-bottom:3rem;
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
        .progress-bar {
            height:10px;
            border:0.5px solid #000000;
            border-radius: 5px;
            position: relative;
            margin-top:2rem;
            margin-left:2rem;
            margin-right:2rem;
            .progress {
                position: absolute;
                border-radius: 5px;
                top:1px;
                bottom:1px;
                left:1px;
                height:6px;
                background-color:#00ff78;
                width:20%;
            }
            .progress-numbers {
                position:absolute;
                right:5px;
                bottom:-25px;
                color:#000000;
                font-size: 12px;
            }
        }
        #question1, #question2, #question3, #question4,#question1-bottom, #question2-bottom, #question3-bottom, #question4-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem;
            position: relative;
            h4 {
                color:#000000;
                margin-top:2rem;
                margin-bottom:2rem;
                font-size:2rem;
                text-align: center;
            }
            .back2,.back3,.back4 {
                position: absolute;
                top:2%;
                left:0;
                color:#000000;
                &:hover {
                    cursor: pointer;
                }
            }
            .answers {
                display:flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                .ans1, .ans2, .ans3, .ans4 {
                    background-color: transparent;
                    flex:0 1 100%;
                    margin:0.5rem;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height:70px;
                    border-radius: 5px;
                    border:1px solid #000000;
                    color:#000000;
                    padding:2rem;
                    &:hover {
                        cursor: pointer;
                        border:2px solid #00ff78;
                    }
                }
            }
        }
        #question5 {
            padding:2rem;
            p {
                font-size:1.6rem !important;
                font-weight: normal;
                max-width:95%;
             }
            h1 {
                margin:0;
                max-width:100%;
                font-size:2.3rem;
                margin-bottom:2rem;
            }
            form {
                input {
                    margin-bottom:2rem !important;
                    border-radius: 5px;
                    color:#000000;
                    &::placeholder {
                        color:#000000;
                    }
                }
                button {
                    float:right;
                }
            }
            #result-debt {
                margin-top:1rem;
                line-height: 3rem;
                font-size:2rem;
            }
        }
    }

}


}
