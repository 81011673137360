/*
	Footer
*/


.footer {
	@media only screen and (max-width: $med) {

		.debt-orgs {
			margin-bottom:25px;
			.wrapper {
				display:flex;
				flex-direction: column !important;
				flex-wrap: wrap;
				padding: 0 2rem !important;
				h2 {
					color:#ffffff;
					font-family: $site-font;
					width:100%;
					text-align: center;
					margin-bottom:40px;
					font-family: "Poppins",sans-serif;
					font-weight: 300;
					font-size: 3rem !important;
					letter-spacing: -0.03em;
					line-height: 3.3rem !important;
					color: #ffffff;
				}
				div {
					max-width: 100% !important;
					display:flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding:0;
					img {
						height:100px;
						margin-bottom:20px;
					}
					h5 {
						color:#ffffff;
						font-weight: bold;
					}
					p {
						padding:0 15px;
					}
				}
			}
			}
		}
background: #005855;
//border: 1px solid #707070;
padding:5.6rem 0;
.wrapper {
	display:flex;
	flex-direction: column;
	align-items: center;
	.footer-sep {
background: transparent;
border: 0.5px solid #fff;
width:100%;
margin:5rem 0;
	}
	p {
		font-family: $site-font;
		font-weight: 300;
		font-size: 1.4rem;
		line-height: 2rem;
		text-align: center;
		color: #fff;
	}
	span a {
		font-family: $site-font;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 2rem;
		text-align: center;
		color: #fff;
	}
}
.debt-orgs {
margin-bottom:25px;
.wrapper {
	display:flex;
	flex-direction: row;
	flex-wrap: wrap;
	h2 {
		color:#ffffff;
		font-family: $site-font;
		width:100%;
		text-align: center;
		margin-bottom:40px;
		font-family: "Poppins",sans-serif;
		font-weight: 300;
		font-size: 5rem;
		letter-spacing: -0.03em;
		line-height: 5.5rem;
		color: #ffffff;
	}
	div {
		max-width: 33.33333%;
		display:flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding:0;
		img {
			height:100px;
			margin-bottom:20px;
		}
		h5 {
			color:#ffffff;
			font-weight: bold;
		}
		p {
			padding:0 15px;
		}
	}
}
}
}

#journey-bottom {
	margin-top:360px;
}