/*
    Page
*/
.page-template-default {
    section {
        background-color:#005855;
        color:#ffffff;
        h1 {
            color:#ffffff;
        }
    }
}