/*
    Header
*/
@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");

.header {
    display:flex;
    justify-content: space-evenly;
    background-color:#005855;
    padding: 2rem 0;
    align-items: center;
    border-bottom: 0.5px solid slategrey;
    img {
        height:58px;
    }
    a {
        margin:0;
        color:#ffffff;
        font-size:20px;
        font-weight: 500;
        width:100%;
        @media only screen and (min-width: $med) {
            max-width: 250px;
        }
        &:hover {
            opacity:0.8;
        }
        img {
            width:100%;
            @media only screen and (min-width: $med) {
                width: auto;
            }
        }
    }
}

.logo {
    flex: 0 1 50%;
}