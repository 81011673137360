.hero-with-image {
    background-color:#005855;
    padding-top: 2rem;
    .wrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            font-family: $heading-font;
            font-weight: 300;
            font-size: 8rem;
            letter-spacing: -0.03em;
            line-height: 8.8rem;
            text-align: center;
            color: #fff;
            strong {
                font-weight: 500;
            }
        }
        p {
            max-width:575px;
            text-align: center;
            font-family: $site-font;
            font-weight: normal;
            font-size: 2rem;
            line-height: 2.6rem;
            text-align: center;
            color: #fff;
            margin-bottom:3rem;
        }
        img {
            margin-bottom:3rem;
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
        .oversized-image {
            margin-bottom:-320px;
            img {
                margin-bottom: 0;
            };
        }
    }
}

.text_image_alt {
    padding-top:320px;
    background-color:#F2F2F2;
    .wrapper {
        padding-top:9rem;
        padding-bottom:10rem;
        display:flex;
        div {
            flex: 0 1 50%;
            &:nth-child(1) {
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                padding-right:3rem;
            }
            &:nth-child(2) {
                display:flex;
                justify-content: flex-end;
            }
            h4 {
                margin:0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                letter-spacing: 0.1em;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 6rem;
                letter-spacing: -0.03em;
                line-height: 6.5rem;
                text-align: left;
                color: #000;
                margin-bottom:2rem;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
                margin: 10px 0;
            }
            .button {
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                margin-top:2rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
        }
    }
}

.image_text {
    background-color:#FFFFFF;
    .wrapper {
        padding-top:9rem;
        padding-bottom:10rem;
        display:flex;
        div {
            flex: 0 1 50%; 
            h4 {
                margin:0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                letter-spacing: 0.1em;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 6rem;
                letter-spacing: -0.03em;
                line-height: 6.5rem;
                text-align: left;
                color: #000;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
            }
            .button {
                margin-left:10%;
                margin-top:2%;
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .bullets {
                .bullet {
                    display:flex;
                    div {
                        &:nth-child(1) {
                            flex:0 1 10%;
                        }
                        h5 {
                            font-family: $site-font;
                            font-weight: 600;
                            font-size: 2rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #005855;
                        }
                        p {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.text_image_alt_bullets {
    padding-top:320px;
    background-color:#F2F2F2;
    .wrapper {
        padding-top:9rem;
        padding-bottom:10rem;
        display:flex;
        div {
            flex: 0 1 50%; 
            &:nth-child(2) {
                display: flex;
                justify-content: flex-end;
            }
            h4 {
                margin:0;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                letter-spacing: 0.1em;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
                text-transform: uppercase;
            }
            h2 {
                font-family: $site-font;
                font-weight: 300;
                font-size: 6rem;
                letter-spacing: -0.03em;
                line-height: 6.5rem;
                text-align: left;
                color: #000;
                strong {
                    font-weight: 500;
                }
            }
            p {
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2.2rem;
                text-align: left;
                color: #000;
            }
            .button {
                
                margin-top:2%;
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .bullets {
                display:flex;
                flex-wrap:wrap;
                .bullet {
                    display:flex;
                    margin-bottom:2rem;
                    justify-content: flex-start;
                    div {
                        flex:0 1 80%;
                        justify-content: flex-start !important;
                        &:nth-child(1) {
                            flex:0 1 15%;
                        }
                        &:nth-child(2) {
                            display:flex;
                            justify-content: flex-start !important;
                        }
                        h5 {
                            font-family: $site-font;
                            font-weight: 500;
                            font-size: 2rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #005855;
                            margin-top: 5px;
                        }
                        p {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.6rem;
                            line-height: 2.2rem;
                            text-align: left;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

.calculator {
    background-color:#005855;
    padding-top:11.7rem;
    .wrapper {
        display:flex;
        flex-direction: column;
        h3 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 6rem;
            letter-spacing: -0.03em;
            line-height: 6.5rem;
            text-align: center;
            color: #fff;
        }
        p {
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2.2rem;
            text-align: center;
            color: #fff;
            max-width:60%;
            margin:0 auto;
            margin-bottom:5rem;
        }

        .calculator-section {
            max-width:80%;
            padding:4.4rem;
            width:100%;
            margin:0 auto;
            margin-bottom:-320px;
            border-radius: 20px;
            background: #fff;
            box-shadow: 6px 7px 16px rgba(0, 0, 0, 0.16);
            .question {
                display:flex;
                flex-wrap: wrap;
                margin-bottom:2rem;
                select {
                    margin-top:1rem;
                    border-radius: 4px;
                    background: #f4f4f4;
                    font-family:Arial, Helvetica, sans-serif;
                    height:47px;
                    font-weight:400;
                    border-radius: 23.5px;
                    background: #fff;
                    border: 2px solid #235755;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.4rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    background: url('../../img/down-arrow.png') no-repeat 95% 50%;
                    -moz-appearance: none; 
                    -webkit-appearance: none; 
                    appearance: none;
                    border-radius: 0;
                    padding-left:2rem;
                }
                input {
                    padding-left:2rem;
                    margin-top:1rem;
                    border-radius: 4px;
                    background: #f4f4f4;
                    font-family:Arial, Helvetica, sans-serif;
                    height:47px;
                    font-weight:400;
                    border-radius: 23.5px;
                    background: #fff;
                    border: 2px solid #235755;
                    font-family: $site-font;
                    font-weight: 300;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    //background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 95% 50%;
                    -moz-appearance: none; 
                    -webkit-appearance: none; 
                    appearance: none;
                }
                &:nth-child(1) {
                    div {
                        width:100%;
                        flex:0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                &:nth-child(2) {
                    div {
                        width:100%;
                        flex:0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                &:nth-child(3) {
                    div {
                        width:100%;
                        flex:0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                &:nth-child(4) {
                    div {
                        width:100%;
                        flex: 0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                &:nth-child(5) {
                    div {
                        width:100%;
                        flex: 0 1 100%;
                        &:nth-child(2) {
                            width:100%;
                        }
                    }
                }
                h5 {
                    width:100%;
                    font-family: $site-font;
                    font-weight: 600;
                    font-size: 2rem;
                    line-height: 2.2rem;
                    text-align: left;
                    color: #005855;
                }
                div {
                    flex: 0 1 50%;
                    font-family: $site-font;
                    font-weight: normal;
                    font-size: 1.6rem;
                    line-height: 2rem;
                    text-align: left;
                    color: #000;
                    display:flex;
                    flex-direction: column;
                    justify-content: center;
                }
                .checkboxes {
                    display:flex;
                    flex-wrap:wrap;
                    flex-direction: row;
                    justify-content: flex-start;
                    .checkbox {
                        flex: 0 1 33.333%;
                        width:33.3333% !important;
                        display:flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        padding:1rem;
                        input {
                            flex: 0 1 10%;
                            align-items: center;
                        }
                        label {
                            font-family: $site-font;
                            font-weight: 300;
                            font-size: 1.4rem;
                            line-height: 2rem;
                            text-align: left;
                            color: #000;
                        }
                    }
                }
            }
            .sep {
                display:none;
                max-width:100%;
                margin:0 auto;
                background: transparent;
                border: 1px solid #707070;
                margin-top:4rem;
                margin-bottom: 4rem;
            }
            .button {
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            .results-total {    
                height: 47px;
                border-radius: 23.5px;
                background: #fff;
                border: 2px solid #235755;
                padding:2rem;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.3rem;
                line-height: 2rem;
                text-align: left;
                color: #000;
            }
        }
    }
}

input[type="checkbox"] {
  display:none;
  opacity: 0;
  z-index: -1;
}

input[type="checkbox"]+span:before {
    content: '';
  border: 1px solid grey;
  border-radius: 10px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  margin-top: 0.5em;
  vertical-align: -2px;
}

input[type="checkbox"]:checked+span:before {
    /* NOTE: Replace the url with a path to an SVG of a checkmark to get a checkmark icon */
    background-image: url('https://cdnjs.cloudflare.com/ajax/libs/ionicons/4.5.6/collection/build/ionicons/svg/ios-checkmark.svg');
    background-repeat: no-repeat;
    background-position: center;
    /* The size of the checkmark icon, you may/may not need this */
    background-size: 25px;
    border-radius: 10px;
    //background-color: #e7ffba;
    color: white;
  }

  input[type="checkbox"]:focus+span:before,
input[type="checkbox"]:not(:disabled)+span:hover:before {
  /* Visible in the full-color space */
  box-shadow: 0px 0px 0px 2px #005855;

  /* Visible in Windows high-contrast themes
     box-shadow will be hidden in these modes and
     transparency will not be hidden in high-contrast
     thus box-shadow will not show but the outline will
     providing accessibility */
  outline-color: transparent;
  outline-width: 2px;
  outline-style: dotted;
  }


/* Disabled checkbox styles */
input[type="checkbox"]:disabled+span {
  cursor: default;
  color: black;
  opacity: 0.5;
}

input {
    height:52px;
}

.journey-question {
    height:calc(100% - 118px);
    background-color:#005855;
    overflow-y: hidden;
    .wrapper {
        .right {
            max-width:50%;
            img {
                max-width:500px;
            }
        }
        .left {
            max-width:50%;
            padding-right:3%;
        }
        height:100%;
        display:flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            letter-spacing: 0.1em;
            line-height: 2.2rem;
            text-align: left;
            color: #fff;
        }
        h2 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 3rem;
            letter-spacing: -0.03em;
            line-height: 3.3rem;
            text-align: left;
            color: #fff;
        }
        h4 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 2rem;
            letter-spacing: -0.03em;
            line-height: 2.2rem;
            text-align: left;
            color: #fff;
        }
        select {
            background-color:transparent;
            border:none;
            border-bottom: 0.5px solid #fff;
            color:#ffffff;
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: left;
            color: #fff;
            padding-left:0;
            margin-bottom:4rem;
            background: url('assets/img/down-arrow.png') no-repeat 98% 50%;
            background-size:8%;
            -moz-appearance: none; 
            -webkit-appearance: none; 
            appearance: none;
            border-radius: 0;
        }
        input {
            background-color:transparent;
            border:none;
            border: 1px solid #fff !important;
            color:#ffffff;
            font-family: $site-font;
            font-weight: 300;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: left;
            color: #fff;
            padding-left:10px !important;
            margin-bottom:4rem;
            max-width:100%;
            &::placeholder {
                color:#ffffff;
            }
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
    }
}

#postcode-error, #phone1-error,#email-error, #firstname-error, #lastname-error {
    //color:#ffffff;
    margin:0 !important;
    margin-bottom:1.5rem !important;
}

form {
    height:100%;
}

.thank-you {
    background-color:#005855;
    .wrapper {
        height:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            font-family: $site-font;
            font-weight: 500;
            font-size: 4rem;
            letter-spacing: -0.03em;
            line-height: 5.8rem;
            text-align: center;
            color: #fff;
        }
        img {
            max-width:150px;
            &:first-of-type {
                max-width:50%;
                margin-bottom:2rem;
            }
        }
        p {
            font-family: $site-font;
            font-weight: normal;
            font-size: 2rem;
            line-height: 2.6rem;
            text-align: center;
            color: #fff;
            max-width:80%;
        }
    }
}

.resultq {
    text-align: center;
    h5 {
        text-align: center !important;
    }
    #result-debt {
        text-align: center !important;
        flex: 0 1 100% !important;
        width:100% !important;
        display:block !important;
        span {
            font-weight: 600;
        }
    }
}

.show#a5-btn {
    display:block !important;
}

#continue, #finish {
    &:hover {
        cursor: pointer;
    }
}


.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  
    option {
      color: #000000;
      padding:2rem;
    }


.journeyform {
    padding-top:2rem;
    background-color:#005855;
    .wrapper {
        display:flex;
        align-items: center;
        justify-content: space-between;
        p {
            color:#ffffff;
        }
        div {
            flex: 0 1 45%;
            h3 {
                font-family: Poppins;
                font-weight: normal;
                font-size: 24px;
                text-align: left;
                color: #fff;
                margin-bottom:5px;
            }
            label {
                font-family: Poppins;
                font-weight: normal;
                font-size: 18px;
                text-align: left;
                color: #fff;
                margin-bottom: 10px;
            }
            select, input {
                margin-top:5px;
            }
            select {
                background-color:transparent;
                border:none;
                border: 0.5px solid #fff;
                color:#ffffff;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: left;
                color: #fff;
                //padding-left:0;
                margin-bottom:2rem;
                background: url('assets/img/down-arrow.png') no-repeat 98% 50%;
                background-size:8%;
                -moz-appearance: none; 
                -webkit-appearance: none; 
                appearance: none;
                border-radius: 0;
            }
            input {
                background-color:transparent;
                border:none;
                border: 1px solid #fff !important;
                color:#ffffff;
                font-family: $site-font;
                font-weight: 300;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: left;
                color: #fff;
                padding-left:1rem !important;
                margin-bottom:2rem;
                max-width:100%;
                margin-top:0;
                &::placeholder {
                    color:#ffffff;
                }
            }
            .button {
                margin-top:10px;
                border:none;
                border-radius: 19px;
                background: #00ff78;
                font-family: $site-font;
                font-weight: 500;
                font-size: 1.6rem;
                line-height: 2rem;
                text-align: center;
                color: #000;
                margin-bottom:3rem;
                &:hover {
                    background-position: 100% 0;
                    moz-transition: all .4s ease-in-out;
                    -o-transition: all .4s ease-in-out;
                    -webkit-transition: all .4s ease-in-out;
                    transition: all .4s ease-in-out;
                    //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                    box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
                }
            }
            #postcode-error, #phone1-error, #email-error, #firstname-error, #lastname-error {
                margin-bottom:0 !important;
                padding:8px;
                background-color:#ffffff;
                color:#005855;
            }
        }
    }
}

#check:disabled {
    opacity:0.5;
}

.hide {
    display:none !important;
}

 #check-results h1 {
    color:#ffffff !important;
    font-size:2.3em !important;
        }

        #debtamount, #resultamount {
            font-weight:bold;
            display:none;
        }

.usps {
    ul {
        list-style: none;
        img {
            filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
            width:40px;
            height:40px;
            margin-right:15px;
        }
        li {
            padding:1rem;
            color:#ffffff;
            font-size:2rem;
            font-weight: bold;
            display: flex;
        align-items: center;
        }
    }
}

.usps-check {
    ul {
        list-style: none;
        padding:0;
        img {
            filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
            width:40px;
            height:40px;
            margin-right:15px;
        }
        li {
            padding:1rem;
            color:#ffffff;
            font-size:2rem;
            font-weight: bold;
            display: flex;
        align-items: center;
        }
    }
}

#check-two {
    &:hover {
        cursor: pointer;
    }
}

#submit {
    &:hover {
        cursor: pointer;
    }
}


.hero-with-journey {
    background-color:#ffffff;
    padding-top: 6rem;
    padding-bottom:9rem;
    .wrapper {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .journey-questions {
            width:100%;
        }
        h1 {
            font-family: $heading-font;
            font-weight: 300;
            font-size: 5rem;
            letter-spacing: -0.03em;
            line-height: 5.5rem;
            text-align: center;
            color: #000000;
            max-width:90%;
            margin-bottom: 9.5rem;
            strong {
                font-weight: 500;
            }
        }
        p {
            max-width:575px;
            text-align: center;
            font-family: $site-font;
            font-weight: normal;
            font-size: 2rem;
            line-height: 2.6rem;
            text-align: center;
            color: #000000;
            margin-bottom:3rem;
        }
        img {
            margin-bottom:3rem;
        }
        .button {
            border:none;
            border-radius: 19px;
            background: #00ff78;
            font-family: $site-font;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2rem;
            text-align: center;
            color: #000;
            margin-bottom:3rem;
            &:hover {
                background-position: 100% 0;
                moz-transition: all .4s ease-in-out;
                -o-transition: all .4s ease-in-out;
                -webkit-transition: all .4s ease-in-out;
                transition: all .4s ease-in-out;
                //background-image: linear-gradient(to right, #00ff78, #3cba92, #30dd8a, #00ff78);
                box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
            }
        }
        .progress-bar {
            height:10px;
            border:0.5px solid #000000;
            border-radius: 5px;
            position: relative;
            margin-top:2rem;
            .progress {
                position: absolute;
                border-radius: 5px;
                top:1px;
                bottom:1px;
                left:1px;
                height:6px;
                background-color:#00ff78;
                width:20%;
            }
            .progress-numbers {
                position:absolute;
                right:5px;
                bottom:-25px;
                color:#000000;
                font-size: 12px;
            }
        }
        #question1, #question2, #question3, #question4, #question1-bottom, #question2-bottom, #question3-bottom, #question4-bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5rem;
            position: relative;
            h4 {
                color:#000000;
                margin-bottom:4rem;
            }
            .back2,.back3,.back4 {
                position: absolute;
                top:5%;
                left:0;
                color:#ffffff;
                &:hover {
                    cursor: pointer;
                }
            }
            .answers {
                display:flex;
                justify-content: space-between;
                width: 100%;
                .ans1, .ans2, .ans3, .ans4 {
                    background-color: transparent;
                    flex:0 1 100%;
                    margin:1rem;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height:70px;
                    border-radius: 5px;
                    border:1px solid #000000;
                    color:#000000;
                    &:hover {
                        cursor: pointer;
                        border:1px solid #00ff78;
                    }
                }
            }
        }
        #question5, #question5-bottom {
            padding:5rem;
            h1 {
                margin:0;
                max-width:100%;
                font-size:2.8rem;
                margin-bottom:2rem;
            }
            p {
                width:100%;
                //margin:0;
                max-width:95%;
                font-weight: 300;
                font-size:1.8rem;
                margin:2rem auto;
                span {
                    font-size:1.4rem;
                }
            }
            form {
                display:flex;
                flex-direction: column;
                input {
                    margin-bottom:2rem !important;
                    border-radius: 5px;
                    border: 1px solid black;
                    color:#000000;
                    &::placeholder, ::-webkit-input-placeholder, :-ms-input-placeholder, ::-moz-placeholder {
                        color:#000000 !important;
                    }
                }
                button {
                    margin:0 auto;
                    padding: 2.5rem 4.5rem;
                }
            }
            #result-debt {
                display:none;
            }
        }
    }

}

.icons-section {
    padding:6rem 0;
    .wrapper {
        h2 {
            text-align: center;
            font-family: "Poppins",sans-serif;
            font-weight: 300;
            font-size: 6rem;
            letter-spacing: -0.03em;
            line-height: 6.5rem;
            color: #000;
            margin-bottom:10px;
            strong {
                font-weight: 500;
            }
        }
        p {
            margin: 0;
            font-family: "Poppins",sans-serif;
            font-weight: 300;
            font-size: 1.6rem;
            letter-spacing: 0.1em;
            line-height: 2.2rem;
            text-align: center;
            color: #000;
            text-transform: uppercase;
            margin-bottom: 25px;
        }
        .icons-list {
            display:flex;
            flex-wrap:wrap;
            .icon-item {
                flex: 0 1 20%;
                .circle {
                    background: #005855;
                    border-radius: 50%;
                    height: 150px;
                    width: 150px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin:0 auto;
                    img {
                        width: 70px;
                    }
                }
                h4 {
                    margin-top:10px;
                    margin-bottom:25px;
                    text-align: center;
                    font-size:1.6rem;
                    color:#000000;
                    font-weight: 400;
                }
            }
        }
    }
}

.page-id-47 .text_image_alt {
    padding-top:2rem !important
}

.lds-dual-ring.hidden {
    display: none;
}

/*Add an overlay to the entire page blocking any further presses to buttons or other elements.*/
.overlay {
   
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: rgba(0,0,0,.8);
    z-index: 999;
    opacity: 1;
    transition: all 0.5s;
    margin:0 auto;
}

/*Spinner Styles*/
.lds-dual-ring {
    display: inline-block;
    p {
        margin:0 !important;
    }
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 44px;
    height: 44px;
    margin: 2% auto;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}