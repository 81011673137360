
.lightbox {
	background: rgba($black, 0.75);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 30;

	&__wrap {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.fa-times {
		position: absolute;
		top: 1em;
		right: 1em;
		color: $white;
		font-size: 3rem;
		&:hover {
			color: $grey;
		}
	}

}