/*
    Front-page
*/
.hero-slider-wrap{
    width: 100%;
    position:relative;
    .bottom-shape{
        position:absolute;
        bottom: -1px;
        z-index:3;
    }
}
.hero-slider{
    .owl-height {
        min-height:700px;
        height: 100% !important;
        @media (max-width:$med) {
            min-height:400px
        }
    }
    position: relative;
    margin-bottom: -2rem;
    @media (max-width:$med){
        margin-bottom: -8rem;
    }
    @media (max-width:$lrg){
        margin-bottom: -9rem;
    }
    .owl-nav {
        display: none;
    }
    .overlay{
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        z-index: 1;
    }
  
    .owl-dots {
        display:block;
        height: 30px;
        margin: 0 auto;
        position:absolute;
        right:0;
        left:0;
        justify-content: flex-start!important;
        margin-left: 15px;
        @media (max-width:$mob){
            bottom: 22rem !important;
        }
        @media (max-width:$med){
            bottom: 26rem !important;
        }
        @media (max-width:$lrg){
            bottom: 29rem !important;
        }

        .owl-dot {
            height: 11px;
            margin-right: 8px;
            width: 11px;
            border:1px $white solid;
            border-radius: 50%;
            background-color: transparent!important;
            &.active{
                border:1px $white solid;
                background-color:$white!important;
            }
        }
        &.wrapper{
            margin:0 auto;
            justify-content: flex-start;
        }
    }
    .slide{
        padding-top:10rem;
        background-size:cover;
        background-position:center center;
        position:relative;
        margin: 0 -1px;
        margin-bottom: 7rem;
 
        @media (max-width:$mob){
            padding-top:1rem !important;
            padding-bottom: 1rem !important;
        }
        @media (max-width:$med){
            padding-top: 16rem;
            padding-bottom: 22rem;
        }
        @media (max-width:$lrg){
            position: relative;
            display: flex;
            align-items: center;
            padding-top: 13rem;
            padding-bottom: 17rem;
        }
        &__background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            z-index: 2;
            min-height:700px;
            height:100%;
            @media (max-width:$med) {
                min-height:400px;
                background-position:center center;
            }
        }
        &__foreground{
            margin-top: 6rem;
            position: relative;
            z-index:9;
            padding: 0 3rem;
            margin-bottom:-5rem;

            img {
                object-fit: contain;
                max-height: 45rem;
            }

            @media (max-width:$lrg){
                margin-right:-10%;
                width: 115%;
                padding-left: 3rem;
                padding: 0 0;
            }

        }
        &__links{
                background-color:#F56247;
                max-width:325px;
                border-radius: 5px;
            @media (max-width:$med){
                display:flex;
              
            }
            a{
                margin-bottom:1rem;
                color:#fff;
                font-weight:700;
                border:none;
                background-color:#F56247;
                width:100%;
                text-align:Center;
                text-transform: uppercase;
                border-radius: 5px;
                @media (max-width:$med){
                    margin-right:1rem;
                    margin-bottom: 0;
                }
                &:last-child{
                    margin:0;
                }
            }
        }
        &__content {
            position: relative;
            z-index: 9;
            width:50%;
            @media (max-width:$med) {
                width:100%;
            }
            @media (max-width:$lrg){
                font-size:2rem;
                max-width: 100%;
                padding: 5rem 1rem 10rem 0;
                background-color:transparent;
            }
            h1{
                display:flex;
                flex-wrap:wrap;
                color: $white;
                font-weight: 600;
                margin-bottom: 0.8rem;
                text-transform: uppercase;
                @media (max-width:$med) {
                    font-size:1.5em;
                }
                span{
                    width: 100%;
                    line-height: 4.9rem;
                    font-size: 5.2rem;
                    font-weight: 300;
                    @media (max-width:$lrg){
                        padding-top:1rem;
                        padding-bottom: 2rem;
                        font-size:12rem;
                        line-height: 11rem;
                    }
                }
            }
        }
        &__description{
            color: $white;
            font-weight:500;
            margin:0;
            padding:0;
            @media (max-width:$med) {
                font-size:1em;
            }
        }
    }
}

.owl-dots {
    top:638px !important;
    @media (max-width:$med) {
        top: 640px !important;
    }
}
.customdots{
    display:flex;
    padding-top:3rem;
    span{
        margin-right:5px;
        width: 10px;
        height:10px;
        border: 1px white solid;
        border-radius:50%;
        &.active{
            background-color:$white;
            border:1px solid $white;
        }
    }
}