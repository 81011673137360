/*
    Pagination
*/

.pagination {
    @extend .clear;
    text-align: center;
}

.page-numbers {
    background: $black;
    color: $white;
    padding: 0.5em 1em;
    border: 1px solid $black;
    @include border-radius(0.5em);
    @include transition(all 0.25s ease);

    &:hover {
        background: $grey;
        color: $white;
        border: 1px solid $black;
    }

    &.current {
        background: $white;
        color: $black;
        border: 1px solid $grey;
    }
}