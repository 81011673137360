/*!
Theme Name: Citizens Debt
Theme URI: https://citizensdebt,org
Author: Citizens Debt
Author URI: https://citizensdebt.org
Description: Wordpress Theme created by Citizens Debt
Version: 1.1.1
*/

@import "vendor/normalize-css/normalize";

@import 'config';

//UTILITIES
@import "utilities/_iframe.scss";
@import "utilities/_keyframes.scss";
@import "utilities/_misc.scss";
@import "utilities/_mixins.scss";

//BASE
@import "base/_main.scss";
@import "base/_print.scss";
@import "base/_type.scss";
@import "base/_wp_core.scss";

//COMPONENTS
@import "components/_buttons.scss";
@import "components/_forms.scss";
@import "components/_lightbox.scss";
@import "components/_modules.scss";
@import "components/_pagination.scss";

// LAYOUT
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_sidebar.scss";
@import "layout/_structure.scss";
@import "layout/_xmobile.scss";

// PAGES
@import "pages/_blog.scss";
@import "pages/_front-page.scss";
@import "pages/_page.scss";


// EXTERNAL LIBRARIES INCLUDED IN style.css FOR LESS REQUESTS
// ENABLE IF NECCESSARY BY UN-COMMENTING BELOW

// # SLICK
// https://github.com/kenwheeler/slick
//$slick-font-path: "assets/scss/vendor/slick/fonts/";
//$slick-loader-path: "assets/scss/vendor/slick/";
// $slick-dot-color: $white;
// $slick-dot-color-active: $green;
// $slick-dot-size: 12px;

//@import 'vendor/slick/slick';
//@import 'vendor/slick/slick-theme';

// # FONT AWESOME
// @import 'vendor/fontawesome/scss/font-awesome';
//$fa-font-path: "assets/scss/vendor/fontawesome/webfonts";
//@import "vendor/fontawesome/scss/fontawesome.scss";
//@import "vendor/fontawesome/scss/fa-solid.scss";
//@import "vendor/fontawesome/scss/fa-brands.scss";


// # ANIMATE.CSS
// https://github.com/daneden/animate.css/
// @import 'vendor/animate-sass/animate';

// # HOVER.CSS
// @import 'vendor/hover/hover';

// # Select2
// @import 'vendor/select2/select2';