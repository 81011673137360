/*
    Miscellaneous
*/

::selection {
    background: $grey;
    color: white;
    text-shadow: none;
}

::-webkit-selection {
    background: $grey;
    color: white;
    text-shadow: none;
}

::-moz-selection {
    background: $grey;
    color: white;
    text-shadow: none;
}

:focus {
    outline-color: transparent;
    outline-style: none;
}