/*
    TYPOGRAPHY
*/


h1, h2, h3, h4, h5, h6 {
    color: #2E2C7A;
    font-weight: 400;
    font-family: $heading-font;
    margin: 0 0 0.5em;
}

h1 {
    font-size: 2.5em; //2.5 body copy
}

h2 {
    font-size: 2.15em;
}

h3 {
    font-size: 1.85em;
}

h4 {
    font-size: 1.55em;
}

h5 {
    font-size: 1.35em;
}

h6 {
    font-size: 1.15em;
}


//body copy
p, ol, ul, li {
    font-size: $basefont;
    line-height: 1.5;
}

.entry-content { //wraps the_content();
}

//link in body copy
.content a {
    text-decoration: none;
    border-bottom: 1px solid $grey;
    font-weight: 600;
    @include transition(all 0.25s ease);

    &:hover {
        border-color: $black;
    }
}
