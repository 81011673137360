// COLORS
$black: #000;
$white: #fff;
$grey: #333	;
$grey-light: #eee;


// BRAND COLORS


// SOCIAL
$facebook: #365397;
$twitter: #00a9f1;
$linkedin: #006db3;
$google: #e0452c;
$instagram: #396d9a;
$tumblr: #304c68;

// GRID
$gridcolumns: 12;

// MEDIA QUERIES
$lrg: 1200px;
$med: 992px;
$sm: 768px;
$mob: 600px;
$exsm: 480px;
$iphone: 320px;

// ALIAS THE SIZES FOR EASE OF READING
$desktop: $lrg;
$laptop: $med;
$tablet: 1024px;
$phone: $exsm;

// FONTS

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

$site-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;

// FONT SIZES
$basefont: 16px;

// easing
$cubic_bez: 	cubic-bezier(0.645, 0.045, 0.355, 1);
