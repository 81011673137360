/*
    Forms
*/

::-webkit-input-placeholder {
   color: #bebebe;
   opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
   color: #bebebe;
   opacity: 1;  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #bebebe;
   opacity: 1;  
}

:-ms-input-placeholder {  
   color: #bebebe;
   opacity: 1;  
} 

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea,
button {
    appearance: none;
    outline: none;
}

input {
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid $black;
}

input[type="submit"] {
    @extend .button;

    cursor: pointer;
}

select,
textarea,
input[type="text"],
input[type="tel"],
input[type="email"] {
    border: 1px solid $grey-light;
    padding: 0.8em 1em;
    width: 100%;
    margin: 0 0 0.5em 0;
    font-family: $site-font;
    opacity: 1;
    color: $grey;
    outline: none;
    transition: all 0.2s ease;
    &:focus {
        border-color: $grey;
    }
}


/*
    Formidable
*/

.frm_form_fields {
    width: 100%;
    margin: 0 0 6px;

    fieldset {
        width: 100%;
        border: 0;
        padding: 0;
        margin: 0;

        .frm_form_field,
        .form-field {
            label {
                margin: 7px 0 5px;
            }
        }
    }

    .frm_submit {
        width: 100%;
        margin: 10px 0 0;

        button {
            @extend .button;

            cursor: pointer;
        }
    }

    .frm_error_style {
        color: rgba(red, 0.8);
    }

    .frm_blank_field.frm_blank_field {
        input[type="text"], input[type="tel"], input[type="email"], textarea {
            border: 1px solid red;
        }
    }
}


.frm_opt_container {
    margin: 0 0 0.5em 0;

    input[type="checkbox"], input[type="radio"] {
        width: auto;
        margin: 0 0.35em 0 0;
    }
}

.frm_hidden {
    display: none;
}

.frm_required {
  left: -0.2em;
  position: relative;
}

.frm_hidden_label {
    .frm_primary_label {
        display: none;
    }
}

.frm_error {
  color: #ff0000;
  font-size: 0.75em;
  margin-top: 0.5em;
}



.frm_dropzone {

    border: 1px dashed #b8b8b8;
    padding: 4em 2em;
    text-align: center;
    transition: all 0.2s ease-in-out 0s;

    &.dz-drag-hover {
        background-color: #daeafe;
    }

    .dz-error-message {
        color: red;
    }

    .frm_compact_text,
    .dz-error-mark,
    .dz-success-mark {
        display: none;
    }

    .frm_small_text {
        font-size: em(12);
        margin-top: 1em;
    }

    .dz-preview {
        margin: 1em 0;
    }


    .dz-progress {
      background: #b8b8b8;
      margin: 1em auto;
      width: 50%;

      .dz-upload {
        background: $grey;
        display: block;
        height: 5px;
      }
    }


    .frm_icon_font {
        display: block;

        &:before {
            display: block;
            margin-bottom: 0.5em;
            font-size: 40px;
            content: "\f093";
            font-family: "FontAwesome";
        }
    }
}