/*
    Buttons
*/

.btn,
.button {
    padding: 0.75em 2.5em;
    display: inline-block;
    text-align: center;
    border: 2px solid $white;
    font-weight: 600;
    transition: all 0.2s ease-in-out 0s;
    position: relative;
    line-height: calc(100% - 1px);

    @media all and (max-width: $sm) {
        width: 100%;
    }

    &:hover {
        background: $black;
        color: $white;
    }

    //add the colors you need
    &--grey {
        border-color: $grey;
        color: $white;

        &:hover {
            background: $grey;
            color: $white;
        }
    }

    &--small {
        font-size: 1.4rem;
    }
}

.post-edit-link {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $grey;
    color: $white;
    padding: 1em 1.5em;
    z-index: 99;
    transition: all 0.2s ease-in-out 0s;

    &:hover {
        background-color: $grey-light;
        color: $grey;
    }
}