/*
    MAIN
*/

/* global box-sizing */
*,
*:after,
*:before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

/* html element 62.5% font-size for REM use */
html,body{
    overflow-x: hidden;
    background-color:#ffffff;
}

html {
    font-size: 62.5%;
    margin: 0;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    font: 300 $basefont $site-font;
    color: $grey;
    margin: 0;
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight:400;
}

/* clear */
.clear:before,
.clear:after {
    content: ' ';
    display: table;
}

.clear:after {
    clear: both;
}

.clear {
    *zoom: 1;
}

img {
    max-width: 100%;
    vertical-align: bottom;
}

a {
    color: $grey;
    text-decoration: none;
}

a:hover {
    color: $grey;
}

a:focus {
    outline: 0;
}

a:hover,
a:active {
    outline: 0;
}


.blackout {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.9;
    display: none;

    &.active {
        display: block;
    }
}

//scroll back to the top of the page
.scroll-to-top {
    position: fixed;
    bottom: -10%;
    right: 3%;
    z-index: 8;
    transition: all 0.2s ease-in-out 0;

    &.active {
        bottom: 3%;
    }
}